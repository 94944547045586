import './list.scss';
import dataApi from '@/api/member/member.js';

export default {
  name: 'total-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        ...this.$store.getters.tableConfig,
        cellBtn: false,
        cancelBtn: false,
        menu: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        filterBtn: false,
        selection: false,
        
        column: [
          {
            label: '会员名称',
            search: true,
            prop: 'name'
          },
          {
            label: '充值金额',
            prop: 'topup_amount'
          },
          {
            label: '充值能量值',
            prop: 'topup_number'
          },
          {
            label: '赠送能量值',
            prop: 'gift_number'
          },
          {
            label: '消耗能量值',
            prop: 'used_number'
          },
          {
            label: '消耗能量值金额',
            prop: 'used_amount'
          },
          {
            label: '退还能量值',
            prop: 'refund_number'
          },
          {
            label: '退还能量值金额',
            prop: 'refund_amount'
          },
          {
            label: '剩余能量值',
            prop: 'net_worth_number'
          },
          {
            label: '剩余能量值金额',
            prop: 'net_worth_amount'
          },
          {
            label: '剩余赠送能量值',
            prop: 'worth_gift_number'
          },
          
          {
            type: 'datetime',
            searchRange: true,
            label: '日期范围',
            span: 8,
            hide: true,
            display: false,
            search: 'true',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ]
      },
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
             
              // 请求列表数据
              dataApi.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      searchData: {
        datetime: []
      }
    };
  },
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选totalList
    searchChange(params, done) {
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 导出
    searchExcel(params) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      
      // 导出
      dataApi.totalExportFile.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.totalList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
